import React, { useState, useEffect, useCallback } from 'react';
import { fabric } from 'fabric';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './App.css';

const App = () => {
  const [images, setImages] = useState([{ case_id: 0, link: '', file_name: '' }]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnnotated, setIsAnnotated] = useState(false);
  const [canvas, setCanvas] = useState(null);
  const [activeTool, setActiveTool] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [strokeColor, setStrokeColor] = useState('#ff0000');
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  // Initialize canvas
  useEffect(() => {
    const newCanvas = new fabric.Canvas('annotationCanvas', {
      selection: false,
      hoverCursor: 'default',
    });
    setCanvas(newCanvas);
    return () => {
      newCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas && images.length > 0) {
      loadImageOnCanvas(images[currentImageIndex].link);
    }
  }, [canvas, currentImageIndex, images]);

  const loadImageOnCanvas = (imageUrl) => {
    if (!canvas) return;
    fabric.Image.fromURL(
      imageUrl,
      (img) => {
        canvas.clear();
        const scale = Math.min(1280 / img.width, 620 / img.height);
        img.scale(scale);
        img.selectable = false;

        // Set canvas dimensions based on scaled image dimensions
        const imgWidth = img.getScaledWidth();
        const imgHeight = img.getScaledHeight();
        canvas.setWidth(imgWidth);
        canvas.setHeight(imgHeight);

        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
        const watermark = new fabric.Text('WIMWIsure AI', {
          left: 10,
          top: 10,
          fontSize: 18,
          fill: 'darkblue',
          fontWeight: 'bold',
          selectable: false,
        });
        canvas.add(watermark);
        setIsAnnotated(false);
        setUndoStack([]);
      },
      { crossOrigin: 'anonymous' }
    );
  };

  // Fetch images from API
  const fetchImages = async (caseId) => {
    if (!caseId.trim()) {
      toast.error('Please enter a valid Case ID.');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://claim.wimwisure.com/util/photos-by-id/${caseId}`
      );
      setImages(response.data);
      setCurrentImageIndex(0);
      toast.success('Images fetched successfully!');
    } catch (error) {
      console.error('Error fetching images:', error);
      toast.error('Failed to fetch images. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Save annotated image
  const saveAnnotatedImage = async () => {
    if (!canvas) return;
    const annotatedImage = canvas.toDataURL();
    const currentImage = images[currentImageIndex];
    const byteString = atob(annotatedImage.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'image/png' });
    try {
      const formData = new FormData();
      formData.append('case_id', currentImage.case_id);
      formData.append('file_name', `ai_${currentImage.file_name}`);
      formData.append('annotated_image', blob, `ai_${currentImage.file_name}`);
      const response = await axios.post(
        'https://claim.wimwisure.com/util/save-ai-photo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        toast.success('Annotated image saved successfully!');
        setIsAnnotated(false);
      }
    } catch (error) {
      console.error('Error saving annotated image:', error);
      toast.error('Failed to save annotated image. Please try again.');
    }
  };

  // Clear canvas (only annotations, preserve watermark)
  const handleClear = () => {
    if (!canvas) return;
    const objects = canvas.getObjects();
    objects.forEach((obj) => {
      if (obj.text !== 'WIMWIsure AI') {
        canvas.remove(obj);
      }
    });
    setUndoStack([]);
    setRedoStack([]);
    canvas.renderAll();
  };

  // Undo last action
  const handleUndo = () => {
    if (!canvas || undoStack.length === 0) return;
    const lastShape = undoStack.pop();
    if (lastShape) {
      canvas.remove(lastShape);
      setRedoStack((prev) => [...prev, lastShape]);
    }
    setUndoStack([...undoStack]);
  };

  // Redo last undone action
  const handleRedo = () => {
    if (!canvas || redoStack.length === 0) return;
    const lastRedoShape = redoStack.pop();
    if (lastRedoShape) {
      canvas.add(lastRedoShape);
      setUndoStack((prev) => [...prev, lastRedoShape]);
    }
    setRedoStack([...redoStack]);
  };

  const handleAnnotationTool = useCallback(
    (tool) => {
      if (!canvas) return;
      setActiveTool(tool);
      canvas.isDrawingMode = false;
      canvas.off('mouse:down');
      canvas.off('mouse:move');
      switch (tool) {
        case 'freehand':
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.color = strokeColor;
          canvas.freeDrawingBrush.width = strokeWidth;
          canvas.on('path:created', (e) => {
            setUndoStack((prev) => [...prev, e.path]);
            setIsAnnotated(true);
          });
          break;
        case 'circle':
          const circleHandler = (event) => {
            const pointer = canvas.getPointer(event.e);
            const circle = new fabric.Circle({
              left: pointer.x,
              top: pointer.y,
              radius: 50,
              stroke: strokeColor,
              strokeWidth: strokeWidth,
              fill: 'transparent',
              selectable: true,
            });
            canvas.add(circle);
            setUndoStack((prev) => [...prev, circle]);
            setIsAnnotated(true);
            canvas.off('mouse:down', circleHandler);
          };
          canvas.on('mouse:down', circleHandler);
          break;
        case 'rectangle':
          const rectangleHandler = (event) => {
            const pointer = canvas.getPointer(event.e);
            const rectangle = new fabric.Rect({
              left: pointer.x,
              top: pointer.y,
              width: 100,
              height: 50,
              stroke: strokeColor,
              strokeWidth: strokeWidth,
              fill: 'transparent',
              selectable: true,
            });
            canvas.add(rectangle);
            setUndoStack((prev) => [...prev, rectangle]);
            setIsAnnotated(true);
            canvas.off('mouse:down', rectangleHandler);
          };
          canvas.on('mouse:down', rectangleHandler);
          break;
        case 'polygon':
          let points = [];
          let polyline;
          const onMouseDown = (event) => {
            const pointer = canvas.getPointer(event.e);
            points.push({ x: pointer.x, y: pointer.y });
            if (points.length > 1) {
              if (polyline) canvas.remove(polyline);
              polyline = new fabric.Polyline(points, {
                fill: 'transparent',
                stroke: strokeColor,
                strokeWidth: strokeWidth,
                selectable: false,
              });
              canvas.add(polyline);
            }
          };
          const onMouseMove = (event) => {
            if (points.length > 0) {
              const pointer = canvas.getPointer(event.e);
              const tempPoints = [...points, { x: pointer.x, y: pointer.y }];
              if (polyline) {
                polyline.set({ points: tempPoints });
              } else {
                polyline = new fabric.Polyline(tempPoints, {
                  fill: 'transparent',
                  stroke: strokeColor,
                  strokeWidth: strokeWidth,
                  selectable: false,
                });
                canvas.add(polyline);
              }
              canvas.renderAll();
            }
          };
          const onEscKey = (e) => {
            if (e.key === 'Escape') {
              if (points.length > 2) {
                points.push(points[0]);
                const polygon = new fabric.Polygon(points, {
                  fill: 'transparent',
                  stroke: strokeColor,
                  strokeWidth: strokeWidth,
                  selectable: true,
                });
                canvas.add(polygon);
                setUndoStack((prev) => [...prev, polygon]);
                setIsAnnotated(true);
              }
              points = [];
              canvas.off('mouse:down', onMouseDown);
              canvas.off('mouse:move', onMouseMove);
              if (polyline) canvas.remove(polyline);
            }
          };
          canvas.on('mouse:down', onMouseDown);
          canvas.on('mouse:move', onMouseMove);
          window.addEventListener('keydown', onEscKey);
          break;
        case 'text':
          canvas.on('mouse:down', (event) => {
            const pointer = canvas.getPointer(event.e);
            const textbox = new fabric.Textbox('Enter text', {
              left: pointer.x,
              top: pointer.y,
              fontSize: strokeWidth * 6,
              fill: strokeColor,
            });
            canvas.add(textbox);
            setUndoStack((prev) => [...prev, textbox]);
            setIsAnnotated(true);
            setActiveTool(null);
            canvas.off('mouse:down');
          });
          break;
        default:
          break;
      }
    },
    [canvas, strokeColor, strokeWidth]
  );

  return (
    <div className="app-container">
      <h1 className="app-title">AI Damage Detection Tool</h1>
      <form onSubmit={(e) => {
        e.preventDefault();
        const caseId = e.target.elements.caseId.value;
        fetchImages(caseId);
      }}>
        <input type="text" name="caseId" placeholder="Case ID" />
        <button type="submit">{isLoading ? 'Fetching...' : 'Fetch Images'}</button>
      </form>
      {images.length > 0 && (
        <>
          <div className="carousel" style={{display: (images[0]?.case_id !== 0) ? 'block' : 'none'}}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image.link}
                alt={image.file_name}
                onClick={() => setCurrentImageIndex(index)}
                className={`thumbnail ${currentImageIndex === index ? 'selected' : ''}`}
                style={{ marginRight: '6px', marginTop: '6px', cursor: 'pointer' }}
              />
            ))}
          </div>
          <div className="annotation-container" style={{display: (images[0]?.case_id !== 0) ? 'block' : 'none'}} >
            <canvas id="annotationCanvas" className="annotation-canvas"></canvas>
          </div>
          <div className="toolbar" style={{display: (images[0]?.case_id !== 0) ? 'block' : 'none'}}>
            <button onClick={() => handleAnnotationTool('freehand')}>Freehand</button>
            <button onClick={() => handleAnnotationTool('circle')}>Circle</button>
            <button onClick={() => handleAnnotationTool('rectangle')}>Rectangle</button>
            <button onClick={() => handleAnnotationTool('polygon')}>Polygon</button>
            <button onClick={() => handleAnnotationTool('text')}>Text</button>
            <button onClick={handleUndo}>Undo</button>
            <button onClick={handleRedo}>Redo</button>
            <button onClick={handleClear}>Clear All</button>
            <button onClick={saveAnnotatedImage}>Save</button>
          </div>
          <div className="shape-controls" style={{display: (images[0]?.case_id !== 0) ? 'block' : 'none'}}>
            <label>
              Stroke Color:
              <input type="color" value={strokeColor} onChange={(e) => setStrokeColor(e.target.value)} />
            </label>
            <label>
              Stroke Width:
              <input type="number" value={strokeWidth} onChange={(e) => setStrokeWidth(Number(e.target.value))} />
            </label>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default App;